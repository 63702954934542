import { Link, type SliceComponentProps } from 'gatsby';
import React, { type FC } from 'react';

import { Logo } from '@/components/icons/logo';
import { site } from '@/utils/site';

interface HeaderLogoProps {
    withLink: boolean;
}

const HeaderLogo: FC<SliceComponentProps<null, HeaderLogoProps>> = ({ sliceContext: { withLink } }) => {
    if (withLink) {
        return (
            <Link to="/" className="d-block lh-0" aria-label={site.name}>
                <Logo />
            </Link>
        );
    }

    return <Logo />;
};

export default HeaderLogo;
